<template>
  <div class="news padding-2" id="news">
    <div class="container-xxl">
      <h1 class="text-center">Updates & News</h1>
      <div class="row mt-5">
        <div class="row">
          <div class="col-md-4">
            <div class="news-inner">
              <div class="tag">
                <ul class="d-flex">
                  <li><small>#Control</small></li>
                  <li><small>#FlameDeFi</small></li>
                </ul>
              </div>
              <div class="mb-4 main-news-image">
                <img src="images/news_control.jpg" />
              </div>
              <div class="p-4">
                <h3>Control Finance Project</h3>
                <span class="d-block mt-3 color-opacity"
                  >FlameDeFi announces the beginning of the development of a new project - an aggregator of Lending&Borrowing protocols on the Tezos blockchain - Control Finance. The SEED sale of Control tokens will begin in the coming days.</span
                >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-3
                    pt-3
                    public-date
                  "
                >
                  <div>
                    <a href="#" target="_blank"
                      ><img src="images/twitter.svg"
                    /></a>
                  </div>
                  <small class="d-block color-opacity"
                    ><img class="me-2 time" src="images/time.svg" />07.11.2021 |
                    Roman Krestianinov</small
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="news-inner">
              <div class="tag">
                <ul class="d-flex">
                  <li><small>#FlameDeFi</small></li>
                  <li><small>#SpaceFarm</small></li>
                </ul>
              </div>
              <div class="mb-4 main-news-image">
                <img src="images/news_farms.jpg" />
              </div>
              <div class="p-4">
                <h3>Launching new farms on SpaceFarm</h3>
                <span class="d-block mt-3 color-opacity"
                  >Now we are preparing to launch 10 new farms for our FlameDEX on November 8-9 , 2021 . In total, more than 20 new farms will be added. Changes will also be made to the old farms.</span
                >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-3
                    pt-3
                    public-date
                  "
                >
                  <div>
                    <a href="#" target="_blank"
                      ><img src="images/twitter.svg"
                    /></a>
                  </div>
                  <small class="d-block color-opacity"
                    ><img class="me-2 time" src="images/time.svg" />6.11.2021 |
                    Audric</small
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="news-inner">
              <div class="tag">
                <ul class="d-flex">
                  <li><small>#FlameDEX</small></li>
                  <li><small>#FlameDeFi</small></li>
                </ul>
              </div>
              <div class="mb-4 main-news-image">
                <img src="images/news_dex.jpg" />
              </div>
              <div class="p-4">
                <h3>FlameDEX is live on mainnet</h3>
                <span class="d-block mt-3 color-opacity"
                  >We are delighted to announce that FlameDex is live on mainnet!
After several months of working to offer you the best AMM possible, the day has finally arrived.
Let's take a look at why FlameDEX is a game changer for Tezos and its DeFi.</span
                >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-3
                    pt-3
                    public-date
                  "
                >
                  <div>
                    <a href="#" target="_blank"
                      ><img src="images/twitter.svg"
                    /></a>
                  </div>
                  <small class="d-block color-opacity"
                    ><img class="me-2 time" src="images/time.svg" />06.11.2021 |
                    Roman Krestianinov</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsBlock",
};
</script>
