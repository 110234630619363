<template>
  <div class="col-md-4">
    <h3 class="thin">
      <small class="d-flex align-items-center">
        <span>fDAO LP &rarr; fDAO:</span></small
      >
      <div class="mt-3">
        APR &mdash;
        <span class="price-up"
          ><strong>{{ fdao_cur.toFixed(2) }}%</strong></span
        >
        APY &mdash;
        <span class="price-up"
          ><strong>{{ fdao_cur2.toFixed(2) }}%</strong></span
        >
      </div>
    </h3>
  </div>
  <div class="col-md-4">
    <h3 class="thin">
      <small class="d-flex align-items-center">
        <span>Flame LP &rarr; Flame:</span></small
      >
      <div class="mt-3">
        APR &mdash;
        <span class="price-up"
          ><strong>{{ flame_cur.toFixed(2) }}%</strong></span
        >
        APY &mdash;
        <span class="price-up"
          ><strong>{{ flame_cur2.toFixed(2) }}%</strong></span
        >
      </div>
    </h3>
  </div>
  <div class="col-md-4">
    <h3 class="thin">
      <small>Total value locked (TVL):</small>
      <div class="mt-2">
        <strong
          >{{ tvl.toLocaleString() }} xtz / $
          {{ tvl2.toLocaleString() }}</strong
        >
      </div>
    </h3>
  </div>
</template>

<script>
export default {
  name: "StatisticsBlock",
  data: function () {
    return {
      fdao_cur: 99.73,
      fdao_cur2: 171,
      flame_cur: 133.96,
      flame_cur2: 281,
      tvl: 143106,
      currs_xtz: 6.7,
    };
  },
  computed: {
    tvl2() {
      return this.tvl * this.currs_xtz.toFixed(2);
    },
  },
};
</script>
