<template>
  <ul>
    <li>
      <a href="https://discord.gg/cz4zXp7Rsh" target="_blank"><img src="images/discord.svg" /></a>
    </li>
    <li>
      <a href="https://github.com/flamedefi/SpaceFarm" target="_blank"><img src="images/github.svg" /></a>
    </li>
    <li>
      <a href="https://flamedefi.medium.com/flame-defi-fb8f5fc8baa9" target="_blank"><img src="images/medium.svg" /></a>
    </li>
    <li>
      <a href="https://twitter.com/FlameDeFi" target="_blank"><img src="images/twitter.svg" /></a>
    </li>
    <li>
      <a href="https://t.me/FLAMEtokenFarm" target="_blank"><img src="images/telegram.svg" /></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SocialLinksBlock",
};
</script>
