<template>
  <div class="col-md-3">
    <div class="road-inner">
      <h3 class="d-block">Q4 2021</h3>
      <span class="d-block mt-3 color-opacity">
        FlameDEX aggregator launch. New Farms. NFT staking. FlameDeFi Metaverse. Control Finance
      </span>
      <div class="road-progress mt-5">
        <div class="progressbar">
          <div :style="{ width: `${road1}%` }"></div>
        </div>
        <h2 v-if="road1 >= 1" class="d-block mt-3">{{ road1 }}%</h2>
        <h2 v-else class="d-block mt-3 progress-null">{{ road1 }}%</h2>
      </div>
      <div class="todolist position-relative">
        <button class="open-todolist"></button>
        <ul class="mt-4">
          <li class="done"><img src="images/tick.svg" />FlameDEX 1.0</li>
          <li class="done">
            <img src="images/tick.svg" />FlameDEX Audit
          </li>
          <li class="done">
            <img src="images/tick.svg" />SpaceFarm Audit
          </li>
          <li class="done">
            <img src="images/tick.svg" />Lottery
          </li>
          <li class="in-progress">NFT Staking</li>
          <li class="in-progress">FlameDEX UI 2.0</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="road-inner">
      <h3 class="d-block">Q1-Q2 2022</h3>
      <span class="d-block mt-3 color-opacity">
        Governance platform launch. Play2Earn game Alfa version launch. Control Finance launch. 
      </span>
      <div class="road-progress mt-5">
        <div class="progressbar">
          <div :style="{ width: `${road2}%` }"></div>
        </div>
        <h2 v-if="road2 >= 1" class="d-block mt-3">{{ road2 }}%</h2>
        <h2 v-else class="d-block mt-3 progress-null">{{ road2 }}%</h2>
      </div>
      <div class="todolist position-relative">
        <button class="open-todolist"></button>
        <ul class="mt-4">
          <li class="in-progress">Governance platform</li>
          <li class="in-progress">Control Finance audit</li>
          <li class="in-progress">Play2Earn audit</li>
          <li class="in-progress">Control Finance Beta</li>
          <li class="in-progress">Play2Earn Beta</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="road-inner">
      <h3 class="d-block">Q2-Q3 2022</h3>
      <span class="d-block mt-3 color-opacity">
        Play2Earn Beta version launch. Control  Finance aggregator launch. FlameDeFi metaverse
      </span>
      <div class="road-progress mt-5">
        <div class="progressbar">
          <div :style="{ width: `${road3}%` }"></div>
        </div>
        <h2 v-if="road3 >= 1" class="d-block mt-3">{{ road3 }}%</h2>
        <h2 v-else class="d-block mt-3 progress-null">{{ road3 }}%</h2>
      </div>
      <div class="todolist position-relative">
        <button class="open-todolist"></button>
        <ul class="mt-4">
          <li class="in-progress">Development</li>
          <li class="in-progress">Audit</li>
          <li class="in-progress">Launch</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="road-inner">
      <h3 class="d-block">Q3 2022</h3>
      <span class="d-block mt-3 color-opacity">
        Play2Earn final version. FlameDEX aggregator final version. Control Finance final version. 
      </span>
      <div class="road-progress mt-5">
        <div class="progressbar">
          <div :style="{ width: `${road4}%` }"></div>
        </div>
        <h2 v-if="road4 >= 1" class="d-block mt-3">{{ road4 }}%</h2>
        <h2 v-else class="d-block mt-3 progress-null">{{ road4 }}%</h2>
      </div>
      <div class="todolist position-relative">
        <button class="open-todolist"></button>
        <ul class="mt-4">
          <li class="in-progress">New features release</li>
          <li class="in-progress">New gameplay</li>
          <li class="in-progress">FlameDEX final update</li>
          <li class="in-progress">Control Finance aggregator</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoadmapBlock",
  data: function () {
    return {
      road1: 65,
      road2: 5,
      road3: 0,
      road4: 0,
    };
  },
};
</script>
