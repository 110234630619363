<template>
  <div class="carousel-item active">
    <h1>FlameDeFi</h1>
    <h2 class="d-block mt-3">
      FlameDEX, SpaceFarm, Control, Lottery and Play2Earn
    </h2>
    <span class="d-block mt-4 w-50"
      >Become a part of the rapidly developing DeFi ecosystem on Tezos</span>
  </div>
  <div class="carousel-item">
    <h1>FlameDEX</h1>
    <h2 class="d-block mt-3">
      Swap token with the best price on Tezos DeFi
    </h2>
    <span class="d-block mt-4 w-50"
      >FlameDEX is the AMM of FlameDeFi and DEX aggregator. This DEX will have a $Flame tokens burn mechanism to reduce the $Flame token supply.</span
    >
  </div>
  <div class="carousel-item">
    <h1>SpaceFarm</h1>
    <h2 class="d-block mt-3">
      Enter on SpaceFarm and earn passive income
    </h2>
    <span class="d-block mt-4 w-50"
      >Stake your $Flame, $fDAO and other tokens with highest APY.</span
    >
  </div>
  <div class="carousel-item">
    <h1>SpaceFarmer NFT</h1>
    <h2 class="d-block mt-3">
      Collect them and take part of the conquest of the Galaxy
    </h2>
    <span class="d-block mt-4 w-50"
      >Collect the SpaceFarmers and stake them to earn token (soon) or use them
      on our next Play2Earn game.</span
    >
  </div>
  <div class="carousel-item">
    <h1>Control</h1>
    <h2 class="d-block mt-3">
      Lending and Borrowing protocol
    </h2>
    <span class="d-block mt-4 w-50"
      >A place where your assets work for you.</span
    >
  </div>
  <div class="carousel-item">
    <h1>Lottery</h1>
    <h2 class="d-block mt-3">
      Play poker and slots to win $Flame tokens
    </h2>
    <span class="d-block mt-4 w-50"
      >Jackpot poker, Carribean poker and Slots working on blockchain.</span
    >
  </div>
</template>

<script>
export default {
  name: "HederCarouselBlock",
};
</script>
